import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import {
    List,
    SelectInput,
    ReferenceInput,
    useRefresh,
    required,
    useNotify,
    useDataProvider,
    useListContext,
    NumberInput,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import {
    Button,
    ImageList,
    ImageListItem,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { BluehillPagination } from './bluehillPagination';
import AuthImage from './components/AuthImage';

function WatchObjectTypeInput() {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = React.useState([]);
    const { setFilters } = useListContext();
    const configuration = useWatch({ name: 'configuration' });

    useEffect(() => {
        dataProvider.getList('robot_watch_object_types', {
            filter: { configuration },
            sort: { field: 'id', order: 'ASC' },
            pagination: { perPage: 50 },
        }).then(({ data }) => {
            setChoices(data);
            if (data.length) {
                setFilters({ configuration, objectId: data[0].id });
            }
        });
    }, [configuration, dataProvider]);

    return configuration == null ? null : (
        <SelectInput choices={choices} source="objectId" optionText="displayname" validate={[required()]} />
    );
}

const ListFilter = [
    // <ReferenceInput
    //     sort={{ field: 'id', order: 'ASC' }}
    //     label="Configuration"
    //     source="configuration"
    //     reference="robots"
    //     alwaysOn
    // >
    //     <AutocompleteInput
    //         optionText="configuration"
    //         validate={[required()]}
    //     />
    // </ReferenceInput>,
    <NumberInput source="configuration" validate={[required()]} alwaysOn />,
    <WatchObjectTypeInput source="objectId" alwaysOn />,
];

const IMAGE_WIDTH = 1280;
const IMAGE_HEIGHT = 960;

function ImageItem(data) {
    const ref = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [updatingItem, setUpdatingItem] = useState(null);
    useLayoutEffect(() => {
        setContainerWidth(ref.current.offsetWidth);
    }, []);
    const dataProvider = useDataProvider();
    const { filterValues } = useListContext();
    const notify = useNotify();
    const refresh = useRefresh();

    // const mockData = {
    //     detectedBoundingBoxes: [
    //         { topLeftPointX: 130, topLeftPointY: 40, xlength: 245, ylength: 124 },
    //         { topLeftPointX: 268, topLeftPointY: 89, xlength: 355, ylength: 400 },
    //     ],
    //     expectedBoundingBox: { topLeftPointX: 294, topLeftPointY: 544, xlength: 292, ylength: 133 },
    // };

    const { detectedBoundingBoxes, expectedBoundingBox: expected } = data;

    const ratio = containerWidth / IMAGE_WIDTH;

    const onChoose = useCallback((item) => {
        setUpdatingItem(item);
    }, [])
    const onClose = useCallback(() => setUpdatingItem(null), []);
    const onUpdate = useCallback(() => {
        dataProvider
            .update('robot_watch_object_history', {
                configuration: filterValues.configuration,
                objectId: filterValues.objectId,
                data: { boundingBox: updatingItem }
            })
            .then((res) => {
                notify('Bounding box updated!');
                onClose();
                refresh();
            })
            .catch((err) => {
                notify(err.message);
            });
    }, [updatingItem]);

    return (
        <div
            ref={ref}
            className="bounding-boxes-container"
        >
            <AuthImage
                url={data.watchObjectImageUrl}
                alt={data.watchObjectImageUrl}
                style={{
                    maxWidth: '100%',
                }}
            />
            {detectedBoundingBoxes?.map((item) => (
                <div
                    key={`${item.topLeftPointX}_${item.topLeftPointY}_${item.xlength}_${item.ylength}`}
                    className="detected-box"
                    style={{
                        left: `${item.topLeftPointX * ratio}px`,
                        top: `${item.topLeftPointY * ratio}px`,
                        width: `${item.xlength * ratio}px`,
                        height: `${item.ylength * ratio}px`,
                    }}
                    onClick={() => {
                        onChoose(item);
                    }}
                />
            ))}
            {expected ? (
                <div
                    key={`${expected.topLeftPointX}_${expected.topLeftPointY}_${expected.xlength}_${expected.ylength}`}
                    className="expected-box"
                    style={{
                        left: `${expected.topLeftPointX * ratio}px`,
                        top: `${expected.topLeftPointY * ratio}px`,
                        width: `${expected.xlength * ratio}px`,
                        height: `${expected.ylength * ratio}px`,
                    }}
                />
            ) : null}
            <Dialog
                open={Boolean(updatingItem)}
                onClose={onClose}
            >
                <DialogTitle>Are you sure you want to update the bounding box?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    The changes will be reflected in the upcoming images.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onUpdate} autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function Container() {
    const { data = [] } = useListContext();
    const cols = useMediaQuery(theme => theme.breakpoints.up('md')) ? 2 : 1;

    return (
        <ImageList cols={cols}>
            {data.map((item, i) => (
                <ImageListItem key={`${i}_${item.watchObjectImageUrl}`}>
                    <ImageItem {...item} />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

export const RobotWatchObjectHistoryList = props => {
    return (
        <List
            {...props}
            syncWithLocation
            sort={{}}
            perPage={50}
            filters={ListFilter}
            filterDefaultValues={{ configuration: 0 }}
            pagination={<BluehillPagination />}
        > 
            <Container />
        </List>
    );
};
